<template>
  <div>
    
  </div>
</template>

<script>
export default {
  name: "Start",
  data() {
    return {};
  }
};
</script>
