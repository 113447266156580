import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    title: "Character generator",
    character: {
      name: "",
      race: "",
      class: "",
      str: { value: 0, mod: 0, req: 0 },
      dex: { value: 0, mod: 0, req: 0 },
      con: { value: 0, mod: 0, req: 0 },
      int: { value: 0, mod: 0, req: 0 },
      wis: { value: 0, mod: 0, req: 0 },
      cha: { value: 0, mod: 0, req: 0 },
      siz: { value: 0, normal: 0, min: 0, max: 0 },
      hit: 0,
      cost: 0
    },
    phases: {
      dod: ["race", "attr"],
      dnd: ["race", "class", "ability-scores"]
    },
    currentPhase: 0
  },
  mutations: {
    setTitle(state, title) {
      state.title = title;
    },
    setRace(state, race) {
      state.character.race = race;
    },
    setClass(state, characterClass) {
      state.character.class = characterClass;
    },
    setModStr(state, str) {
      state.character.str.mod = str ?? 0;
    },
    setModDex(state, dex) {
      state.character.dex.mod = dex ?? 0;
    },
    setModCon(state, con) {
      state.character.con.mod = con ?? 0;
    },
    setModInt(state, int) {
      state.character.int.mod = int ?? 0;
    },
    setModWis(state, wis) {
      state.character.wis.mod = wis ?? 0;
    },
    setModCha(state, cha) {
      state.character.cha.mod = cha ?? 0;
    },
    setNormalSiz(state, siz) {
      state.character.siz.normal = siz;
    },
    setMaxSiz(state, siz) {
      state.character.siz.max = siz;
    },
    setMinSiz(state, siz) {
      state.character.siz.min = siz;
    },
    setStr(state, str) {
      state.character.str.value = str;
    },
    setDex(state, dex) {
      state.character.dex.value = dex;
    },
    setCon(state, con) {
      state.character.con.value = con;
    },
    setInt(state, int) {
      state.character.int.value = int;
    },
    setWis(state, wis) {
      state.character.wis.value = wis;
    },
    setCha(state, cha) {
      state.character.cha.value = cha;
    },
    setSiz(state, siz) {
      state.character.siz.value = siz;
    },
    setHit(state, hit) {
      state.character.hit = hit;
    },
    setReqStr(state, str) {
      state.character.str.req = str ?? 0;
    },
    setReqDex(state, dex) {
      state.character.dex.req = dex ?? 0;
    },
    setReqCon(state, con) {
      state.character.con.req = con ?? 0;
    },
    setReqInt(state, int) {
      state.character.int.req = int ?? 0;
    },
    setReqWis(state, wis) {
      state.character.wis.req = wis ?? 0;
    },
    setReqCha(state, cha) {
      state.character.cha.req = cha ?? 0;
    },
    setCurrentPhase(state, phase) {
      state.currentPhase = phase;
    }
  },
  actions: {
    setTitle({ commit }, title) {
      commit("setTitle", title);
    },
    setRace({ commit }, race) {
      commit("setRace", race);
    },
    setClass({ commit }, characterClass) {
      commit("setClass", characterClass);
    },
    setModStr({ commit }, str) {
      commit("setModStr", str);
    },
    setModDex({ commit }, dex) {
      commit("setModDex", dex);
    },
    setModCon({ commit }, con) {
      commit("setModCon", con);
    },
    setModInt({ commit }, int) {
      commit("setModInt", int);
    },
    setModWis({ commit }, wis) {
      commit("setModWis", wis);
    },
    setModCha({ commit }, cha) {
      commit("setModCha", cha);
    },
    setNormalSiz({ commit }, siz) {
      commit("setNormalSiz", siz);
    },
    setMaxSiz({ commit }, siz) {
      commit("setMaxSiz", siz);
    },
    setMinSiz({ commit }, siz) {
      commit("setMinSiz", siz);
    },
    setStr({ commit }, str) {
      commit("setStr", str);
    },
    setDex({ commit }, dex) {
      commit("setDex", dex);
    },
    setCon({ commit }, con) {
      commit("setCon", con);
    },
    setInt({ commit }, int) {
      commit("setInt", int);
    },
    setWis({ commit }, wis) {
      commit("setWis", wis);
    },
    setCha({ commit }, cha) {
      commit("setCha", cha);
    },
    setSiz({ commit }, siz) {
      commit("setSiz", siz);
    },
    setReqStr({ commit }, str) {
      commit("setReqStr", str);
    },
    setReqDex({ commit }, dex) {
      commit("setReqDex", dex);
    },
    setReqCon({ commit }, con) {
      commit("setReqCon", con);
    },
    setReqInt({ commit }, int) {
      commit("setReqInt", int);
    },
    setReqWis({ commit }, wis) {
      commit("setReqWis", wis);
    },
    setReqCha({ commit }, cha) {
      commit("setReqCha", cha);
    },
    setHit({ commit }, hit) {
      commit("setHit", hit);
    },
    nextPhase({ commit, state }) {
      commit("setCurrentPhase", state.currentPhase + 1);
    },
    previousPhase({ commit, state }) {
      commit("setCurrentPhase", state.currentPhase - 1);
    }
  },
  modules: {}
});
