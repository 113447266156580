function calculate(totalHit) {
  let body = {};

  switch (true) {
    case totalHit < 8:
      body = {
        chest: 4,
        rightLeg: 3,
        leftLeg: 3,
        stomach: 3,
        rightArm: 2,
        leftArm: 2,
        head: 3
      };
      break;
    case totalHit < 12:
      body = {
        chest: 5,
        rightLeg: 4,
        leftLeg: 4,
        stomach: 4,
        rightArm: 3,
        leftArm: 3,
        head: 4
      };
      break;
    case totalHit < 16:
      body = {
        chest: 6,
        rightLeg: 5,
        leftLeg: 5,
        stomach: 5,
        rightArm: 4,
        leftArm: 4,
        head: 5
      };
      break;
    case totalHit < 21:
      body = {
        chest: 7,
        rightLeg: 6,
        leftLeg: 6,
        stomach: 6,
        rightArm: 5,
        leftArm: 5,
        head: 6
      };
      break;
    case totalHit < 26:
      body = {
        chest: 8,
        rightLeg: 7,
        leftLeg: 7,
        stomach: 7,
        rightArm: 6,
        leftArm: 6,
        head: 7
      };
      break;
    case totalHit < 31:
      body = {
        chest: 9,
        rightLeg: 8,
        leftLeg: 8,
        stomach: 8,
        rightArm: 7,
        leftArm: 7,
        head: 8
      };
      break;
    case totalHit < 36:
      body = {
        chest: 10,
        rightLeg: 9,
        leftLeg: 9,
        stomach: 9,
        rightArm: 8,
        leftArm: 8,
        head: 9
      };
      break;
    case totalHit < 41:
      body = {
        chest: 11,
        rightLeg: 10,
        leftLeg: 10,
        stomach: 10,
        rightArm: 9,
        leftArm: 9,
        head: 10
      };
      break;
    case totalHit > 40: {
      const extra = Math.floor((totalHit - 40) / 5);
      body = {
        chest: 11 + extra,
        rightLeg: 10 + extra,
        leftLeg: 10 + extra,
        stomach: 10 + extra,
        rightArm: 9 + extra,
        leftArm: 9 + extra,
        head: 10 + extra
      };
      break;
    }
    default:
      body = {
        chest: 0,
        rightLeg: 0,
        leftLeg: 0,
        stomach: 0,
        rightArm: 0,
        leftArm: 0,
        head: 0
      };
      break;
  }

  return body;
}

export default calculate;
