<template>
  <v-card>
    <v-select
      v-model="chosenClass"
      :items="classes"
      v-on:change="select"
      item-text="name"
      label="Select class"
      persistent-hint
      return-object
      single-line
    ></v-select>
    <v-card-actions>
      <Stepper end="2" />
    </v-card-actions>
  </v-card>
</template>

<script>
import Stepper from "@/components/Stepper.vue";
export default {
  name: "DndClass",
  components: { Stepper },
  data() {
    return {
      classes: [{ name: "Barbarian", hitDie: "12" }],
      chosenClass: {}
    };
  },
  methods: {
    select() {
      this.$store.dispatch("setHit", this.chosenClass.hitDie);
      this.$store.dispatch("setClass", this.chosenClass.name);
    }
  }
};
</script>
