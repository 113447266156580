<template>
  <div>
    <DndRace v-if="phases[currentPhase] == 'race'" />
    <DndClass v-if="phases[currentPhase] == 'class'" />
    <AbilityScores v-if="phases[currentPhase] == 'ability-scores'" />
    <v-card>
      <v-card-title>
        Character
      </v-card-title>
      <v-card-subtitle>
        {{ character.race }} {{ character.class }}
      </v-card-subtitle>
      <v-card-text>
        Str: {{ character.str }} Dex: {{ character.dex }}<br />
        Con: {{ character.con }} Int: {{ character.int }}<br />
        Wis: {{ character.wis }} Cha: {{ character.cha }}<br />
        Hit Points: {{ character.hit }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DndRace from "@/components/dnd/Race.vue";
import DndClass from "@/components/dnd/Class.vue";
import AbilityScores from "@/components/dnd/AbilityScores.vue";
export default {
  name: "Dnd",
  components: { DndRace, DndClass, AbilityScores },
  computed: {
    character() {
      return this.$store.state.character;
    },
    phases() {
      return this.$store.state.phases.dnd;
    },
    currentPhase() {
      return this.$store.state.currentPhase;
    }
  }
};
</script>
