<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-select
            v-model="race"
            :items="$options.races"
            v-on:change="selectRace"
            item-text="name"
            label="Välj ras"
            persistent-hint
            return-object
            single-line
          ></v-select>
        </v-card>
        <v-card>
          <v-select
            v-model="selectedClass"
            :items="$options.classes"
            v-on:change="selectClass"
            item-text="name"
            label="Välj yrke"
            persistent-hint
            return-object
            single-line
          ></v-select>
        </v-card>

        <v-container>
          <v-row>
            <v-col>Grund</v-col>
            <v-col>Köp</v-col>
            <v-col>Mod</v-col>
            <v-col>Tot</v-col>
            <v-col>BP kost.</v-col>
          </v-row>
          <v-row>
            <v-col>
              STY
              {{ character.str.req != 0 ? "(" + character.str.req + ")" : "" }}
            </v-col>
            <v-col>
              <v-text-field
                type="text"
                dense
                size="2"
                maxlength="2"
                v-model.number="str"
                :rules="inputRules.strRules"
              ></v-text-field>
            </v-col>
            <v-col>{{ character.str.mod != 0 ? character.str.mod : "" }}</v-col>
            <v-col>{{ str + character.str.mod }}</v-col>
            <v-col>{{ $options.attrCost[str] }}</v-col>
          </v-row>
          <v-row>
            <v-col>
              FYS
              {{ character.con.req != 0 ? "(" + character.con.req + ")" : "" }}
            </v-col>
            <v-col>
              <v-text-field
                type="text"
                dense
                size="2"
                maxlength="2"
                v-model.number="con"
                :rules="inputRules.conRules"
              ></v-text-field>
            </v-col>
            <v-col>{{ character.con.mod != 0 ? character.con.mod : "" }}</v-col>
            <v-col>{{ con + character.con.mod }}</v-col>
            <v-col>{{ $options.attrCost[con] }}</v-col>
          </v-row>
          <v-row>
            <v-col>
              SMI
              {{ character.dex.req != 0 ? "(" + character.dex.req + ")" : "" }}
            </v-col>
            <v-col>
              <v-text-field
                type="text"
                dense
                size="2"
                maxlength="2"
                v-model.number="dex"
                :rules="inputRules.dexRules"
              ></v-text-field>
            </v-col>
            <v-col>{{ character.dex.mod != 0 ? character.dex.mod : "" }}</v-col>
            <v-col>{{ dex + character.dex.mod }}</v-col>
            <v-col>{{ $options.attrCost[dex] }}</v-col>
          </v-row>
          <v-row>
            <v-col>
              INT
              {{ character.int.req != 0 ? "(" + character.int.req + ")" : "" }}
            </v-col>
            <v-col>
              <v-text-field
                type="text"
                dense
                size="2"
                maxlength="2"
                v-model.number="int"
                :rules="inputRules.intRules"
              ></v-text-field>
            </v-col>
            <v-col>{{ character.int.mod != 0 ? character.int.mod : "" }}</v-col>
            <v-col>{{ int + character.int.mod }}</v-col>
            <v-col>{{ $options.attrCost[int] }}</v-col>
          </v-row>
          <v-row>
            <v-col>
              PSY
              {{ character.wis.req != 0 ? "(" + character.wis.req + ")" : "" }}
            </v-col>
            <v-col>
              <v-text-field
                type="text"
                dense
                size="2"
                maxlength="2"
                v-model.number="wis"
                :rules="inputRules.wisRules"
              ></v-text-field>
            </v-col>
            <v-col>{{ character.wis.mod != 0 ? character.wis.mod : "" }}</v-col>
            <v-col>{{ wis + character.wis.mod }}</v-col>
            <v-col>{{ $options.attrCost[wis] }}</v-col>
          </v-row>
          <v-row>
            <v-col>
              KAR
              {{ character.cha.req != 0 ? "(" + character.cha.req + ")" : "" }}
            </v-col>
            <v-col>
              <v-text-field
                type="text"
                dense
                size="2"
                maxlength="2"
                v-model.number="cha"
                :rules="inputRules.chaRules"
              ></v-text-field>
            </v-col>
            <v-col>{{ character.cha.mod != 0 ? character.cha.mod : "" }}</v-col>
            <v-col>{{ cha + character.cha.mod }}</v-col>
            <v-col>{{ $options.attrCost[cha] }}</v-col>
          </v-row>
          <v-row>
            <v-col>
              STO
              {{
                character.siz.min != 0
                  ? "(" + character.siz.min + " - " + character.siz.max + ")"
                  : ""
              }}
            </v-col>
            <v-col>
              <v-text-field
                type="text"
                dense
                size="2"
                maxlength="2"
                v-model.number="siz"
                :rules="inputRules.sizRules"
              ></v-text-field>
            </v-col>
            <v-col></v-col>
            <v-col></v-col>
            <v-col>{{ sizCost }}</v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          Totalt spenderad BP:
          {{ totalCost }}
          <br />
          BP kvar: {{ 125 - totalCost }}
        </v-card>
      </v-col>
      <v-col>
        <v-container>
          <h3>Karaktär</h3>
          <v-row>
            <v-col>Ras: {{ race.name }} ({{ race.cost }} BP)</v-col>
          </v-row>
          <v-row>
            <v-col>Yrke: {{ selectedClass.name }}</v-col>
          </v-row>
          <v-row>
            <v-col>KP: {{ hitPoints }}</v-col>
          </v-row>
          <v-row>
            <v-col>Bröstkorg: {{ bodyHitPoints.chest }}</v-col>
            <v-col>Höger ben: {{ bodyHitPoints.rightLeg }}</v-col>
            <v-col>Vänster ben: {{ bodyHitPoints.leftLeg }}</v-col>
            <v-col>Mage: {{ bodyHitPoints.stomach }}</v-col>
          </v-row>
          <v-row>
            <v-col>Höger arm: {{ bodyHitPoints.rightArm }}</v-col>
            <v-col>Vänster arm: {{ bodyHitPoints.leftArm }}</v-col>
            <v-col>Huvud: {{ bodyHitPoints.head }}</v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import racesJson from "../../data/dod/races.json";
import classesJson from "../../data/dod/clazzes.json";
import attrCostJson from "../../data/dod/attr-cost.json";
import sizNegCostJson from "../../data/dod/siz-neg-cost.json";
import bodyHit from "../../rules/dod/body-hit";
export default {
  name: "DodRace",
  mounted() {
    this.$store.dispatch("setTitle", "Drakar & Demoner");
  },
  races: racesJson,
  classes: classesJson,
  attrCost: attrCostJson,
  sizNegCost: sizNegCostJson,
  data() {
    return {
      inputRules: {
        sizRules: [
          value =>
            (value >= this.character.siz.min &&
              value <= this.character.siz.max) ||
            "Storlek utanför gräns!"
        ],
        strRules: [
          value =>
            value + this.character.str.mod >= this.character.str.req ||
            "För låg styrka!"
        ],
        dexRules: [
          value =>
            value + this.character.dex.mod >= this.character.dex.req ||
            "För låg smidighet!"
        ],
        conRules: [
          value =>
            value + this.character.con.mod >= this.character.con.req ||
            "För låg fysik!"
        ],
        intRules: [
          value =>
            value + this.character.int.mod >= this.character.int.req ||
            "För låg intelligens!"
        ],
        wisRules: [
          value =>
            value + this.character.wis.mod >= this.character.wis.req ||
            "För lågt psyke!"
        ],
        chaRules: [
          value =>
            value + this.character.cha.mod >= this.character.cha.req ||
            "För låg karisma!"
        ]
      },

      selectedClass: {},
      race: {}
    };
  },
  computed: {
    character() {
      return this.$store.state.character;
    },
    totalCost() {
      let cost =
        (this.race.cost ?? 0) +
        (this.$options.attrCost[this.str] ?? 0) +
        (this.$options.attrCost[this.dex] ?? 0) +
        (this.$options.attrCost[this.con] ?? 0) +
        (this.$options.attrCost[this.int] ?? 0) +
        (this.$options.attrCost[this.wis] ?? 0) +
        (this.$options.attrCost[this.cha] ?? 0) +
        this.sizCost;

      return cost;
    },
    sizCost() {
      if (this.siz > this.character.siz.normal) {
        return (this.siz - this.character.siz.normal) * 2 ?? 0;
      } else if (this.siz < this.character.siz.normal) {
        return (
          this.$options.sizNegCost[this.character.siz.normal - this.siz] ?? 0
        );
      } else {
        return 0;
      }
    },
    hitPoints() {
      return Math.ceil(
        (this.character.con.value + this.character.siz.value) / 2
      );
    },
    bodyHitPoints() {
      return bodyHit(this.hitPoints);
    },
    str: {
      get() {
        return this.$store.state.character.str.value;
      },
      set(value) {
        this.$store.dispatch("setStr", value);
      }
    },
    dex: {
      get() {
        return this.$store.state.character.dex.value;
      },
      set(value) {
        this.$store.dispatch("setDex", value);
      }
    },
    con: {
      get() {
        return this.$store.state.character.con.value;
      },
      set(value) {
        this.$store.dispatch("setCon", value);
      }
    },
    int: {
      get() {
        return this.$store.state.character.int.value;
      },
      set(value) {
        this.$store.dispatch("setInt", value);
      }
    },
    wis: {
      get() {
        return this.$store.state.character.wis.value;
      },
      set(value) {
        this.$store.dispatch("setWis", value);
      }
    },
    cha: {
      get() {
        return this.$store.state.character.cha.value;
      },
      set(value) {
        this.$store.dispatch("setCha", value);
      }
    },
    siz: {
      get() {
        return this.$store.state.character.siz.value;
      },
      set(value) {
        this.$store.dispatch("setSiz", value);
      }
    }
  },
  methods: {
    selectRace() {
      this.$store.dispatch("setModStr", this.race.str);
      this.$store.dispatch("setModDex", this.race.dex);
      this.$store.dispatch("setModCon", this.race.con);
      this.$store.dispatch("setModInt", this.race.int);
      this.$store.dispatch("setModWis", this.race.wis);
      this.$store.dispatch("setModCha", this.race.cha);
      this.$store.dispatch("setNormalSiz", this.race.siz.normal);
      this.$store.dispatch("setMaxSiz", this.race.siz.max);
      this.$store.dispatch("setMinSiz", this.race.siz.min);
      this.$store.dispatch("setRace", this.race.name);
      this.siz = this.race.siz.normal;
    },
    selectClass() {
      this.$store.dispatch("setReqStr", this.selectedClass.str);
      this.$store.dispatch("setReqDex", this.selectedClass.dex);
      this.$store.dispatch("setReqCon", this.selectedClass.con);
      this.$store.dispatch("setReqInt", this.selectedClass.int);
      this.$store.dispatch("setReqWis", this.selectedClass.wis);
      this.$store.dispatch("setReqCha", this.selectedClass.cha);
      this.$store.dispatch("setClass", this.selectedClass.name);
    }
  }
};
</script>

<style></style>
