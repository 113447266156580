<template>
  <v-card>
    <v-select
      v-model="race"
      :items="races"
      v-on:change="select"
      item-text="name"
      label="Select race"
      persistent-hint
      return-object
      single-line
    ></v-select>

    <v-card-actions>
      <Stepper end="2" />
    </v-card-actions>
  </v-card>
</template>

<script>
import Stepper from "@/components/Stepper.vue";
export default {
  name: "DndRace",
  components: { Stepper },
  data() {
    return {
      races: [
        { name: "Elf", str: 0, dex: 2, con: 0, int: 0, wis: 0, cha: 0 },
        { name: "Human", str: 1, dex: 1, con: 1, int: 1, wis: 1, cha: 1 }
      ],
      race: {}
    };
  },
  computed: {
    character() {
      return this.$store.state.character;
    }
  },
  methods: {
    select() {
      this.$store.dispatch("setModStr", this.race.str);
      this.$store.dispatch("setModDex", this.race.dex);
      this.$store.dispatch("setModCon", this.race.con);
      this.$store.dispatch("setModInt", this.race.int);
      this.$store.dispatch("setModWis", this.race.wis);
      this.$store.dispatch("setModCha", this.race.cha);
      this.$store.dispatch("setRace", this.race.name);
    },
    next() {
      this.$store.dispatch("nextPhase");
    }
  }
};
</script>

<style></style>
