<template>
  <div>
    <v-btn @click="back()" v-if="this.$store.state.currentPhase > 0">
      Back
    </v-btn>
    <v-btn @click="next()" v-if="end > this.$store.state.currentPhase">
      Next
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "Stepper",
  props: ["end"],
  methods: {
    next() {
      this.$store.dispatch("nextPhase");
    },
    back() {
      this.$store.dispatch("previousPhase");
    }
  }
};
</script>
