<template>
  <div>
    <v-card>
      <v-btn @click="rollAbilityScores()">
        ROLL!
      </v-btn>
      <div>
        {{ rolls }}
      </div>
      <v-card-actions>
        <Stepper end="2" />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Stepper from "@/components/Stepper.vue";
export default {
  name: "AbilityScores",
  components: { Stepper },
  data() {
    return {
      rolls: []
    };
  },
  methods: {
    rollAbilityScores() {
      let resultList = [];
      resultList.push(this.roll(1, 6));
      resultList.push(this.roll(1, 6));
      resultList.push(this.roll(1, 6));
      resultList.push(this.roll(1, 6));

      this.rolls.push(resultList);
      resultList = [];
      resultList.push(this.roll(1, 6));
      resultList.push(this.roll(1, 6));
      resultList.push(this.roll(1, 6));
      resultList.push(this.roll(1, 6));
      this.rolls.push(resultList);
    },
    roll(dices, sides) {
      let result = 0;
      for (let index = 0; index < dices; index++) {
        let roll = Math.floor(Math.random() * sides) + 1;
        result += roll;
        console.debug(
          "Dice: " + (index + 1) + "/" + dices + " d" + sides + " -> " + roll
        );
      }
      console.debug("Result: " + result);
      return result;
    }
  }
};
</script>
