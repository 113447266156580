<template>
  <div>
    <DodRace />
  </div>
</template>

<script>
import DodRace from "@/components/dod/DodRace.vue";
export default {
  name: "Dod",
  components: { DodRace }
};
</script>
