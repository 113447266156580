import Vue from "vue";
import Router from "vue-router";
import DefaultLayout from "@/layouts/Default.vue";
import Start from "@/views/Start.vue";
import Dod from "@/views/Dod.vue";
import Dnd from "@/views/Dnd.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      component: DefaultLayout,
      redirect: { name: "start" },
      children: [
        {
          path: "/start",
          name: "start",
          redirect: { name: "dod" },
          component: Start
        },
        {
          path: "/dod",
          name: "dod",
          component: Dod
        },
        {
          path: "/dnd",
          name: "dnd",
          component: Dnd
        }
      ]
    }
  ]
});
